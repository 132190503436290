import React, { useState, useLayoutEffect }  from "react"
import { Link } from "gatsby"
import layoutStyles from "./layout.module.scss"
import { motion, AnimatePresence   } from "framer-motion"
import { faLinkedin, faInstagram, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import topleft from "../assets/topleft.svg";
import bottomleft from "../assets/bottomleft.svg";
import bottomright from "../assets/bottomright.svg";


  
const MenuLink = props => {
     return (
            <Link to={props.path} >
                <div className={layoutStyles.menulink} >
                        <p>{props.name}</p>
                </div>
            </Link>
     )
}

const Bubble = props => {

    return (
        <motion.use
        initial={{scale: 0}}
        animate={{scale: 1}}
        whileTap={{ scale: 0.5}}
        transition={{duration:1}}
        className={props.className}
        style={{ opacity: props.yRange }}
        href={`${props.bubbleArea}#${props.idName}`}
        />
    )
}

export default ( props ) => {

    const [zIndex, setZIndex] = useState(0);
    useLayoutEffect(() => {
        window.onscroll = () => {
            let currentScrollPos = window.pageYOffset;
            let maxScroll = document.body.scrollHeight - window.innerHeight;
            if (currentScrollPos > 100 && currentScrollPos < maxScroll) {
              setZIndex(-1000);
               console.log(currentScrollPos)
            } else {
              setZIndex(1);
            }
          }
      });

  return (
      <div className={layoutStyles.parent}>
        <AnimatePresence>
            <motion.div 
                style={{ opacity: props.yRange, 
                        zIndex: zIndex }}
                transition={{duration:0.3}}

                className={layoutStyles.header}>
                <MenuLink name="Home" path="/"/>
                <MenuLink name="About" path="/about/"/>
                <MenuLink name="Work" path="/work/"/>
                <a href="mailto:philippetercohn@gmail.com" target="_blank" rel="noopener noreferrer">
                        <div className={layoutStyles.menulink} >
                            <p>Contact</p>
                        </div>
                </a>
                <div className={layoutStyles.externalLinks}>
                    <a className={layoutStyles.externalLink} href="mailto:philippetercohn@gmail.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </a>
                    <a className={layoutStyles.externalLink} href="https://www.linkedin.com/in/phil-cohn-2569a343/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin}/>
                    </a>
                    <a className={layoutStyles.externalLink} href="https://www.instagram.com/philcohnartist/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                    <a className={layoutStyles.externalLink} href="https://github.com/IamcalledPhil" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGithub}/>
                    </a>
                    <a className={layoutStyles.externalLink} href="https://Philcohnartist.redbubble.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faShoppingCart}/>
                    </a>
                    <a className={layoutStyles.externalLink} href="https://medium.com/@philcohn" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faMedium}/>
                    </a>
                </div>

            </motion.div>
            <div 
                className={layoutStyles.content}>
                    {props.children}
            </div>
            <div 
                className={layoutStyles.upperleft}>
                <motion.svg
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 461.42 393.59">
                    <Bubble idName='Path_73' 
                        bubbleArea={topleft}
                        yRange={props.yRange}
                        className={layoutStyles.lightBlueBlob}/>
                    <Bubble idName='Path_276-2' 
                        bubbleArea={topleft}
                        yRange={props.yRange}
                        className={layoutStyles.salmonBlob}/>
                    <Bubble idName='Path_276' 
                        bubbleArea={topleft}
                        yRange={props.yRange}
                        className={layoutStyles.darkGreenBlob}/>
                </motion.svg>
            </div>
            <div 
                className={layoutStyles.lowerleft}>
                <motion.svg
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 389.01 243.74">
                    <Bubble idName='Path_73' 
                        bubbleArea={bottomleft}
                        yRange={props.yRange}
                        className={layoutStyles.darkBlueBlob}/>
                    <Bubble idName='Path_74' 
                        bubbleArea={bottomleft}
                        yRange={props.yRange}
                        className={layoutStyles.salmonBlob}/>
                    <Bubble idName='Path_74-2' 
                        bubbleArea={bottomleft}
                        yRange={props.yRange}
                        className={layoutStyles.lightGreenBlob}/>
                 </motion.svg>
            </div>
            <div 
                className={layoutStyles.lowerright}>
                <motion.svg
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 210.65 160.17">
                    <Bubble idName='Path_72' 
                        bubbleArea={bottomright}
                        yRange={props.yRange}
                        className={layoutStyles.darkBlueBlob}/>
                    <Bubble idName='Path_277' 
                        bubbleArea={bottomright}
                        yRange={props.yRange}
                        className={layoutStyles.salmonBlob}/>
                    <Bubble idName='Path_277-2' 
                        bubbleArea={bottomright}
                        yRange={props.yRange}
                        className={layoutStyles.lightGreenBlob}/>
                 </motion.svg>
            </div>
        </AnimatePresence>

    </div>
  )
}